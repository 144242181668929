import { graphql } from 'babel-plugin-relay/macro';

export const QuoteQuery = graphql`
  query QuoteQuery {
    quotes {
      id
      createdAt
      updatedAt
      name
      designation
      text
      isActive
      sortOrder
      image
      imageUrl
    }
  }
`;

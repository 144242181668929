import { graphql } from 'babel-plugin-relay/macro';

export const BlogDetailsQuery = graphql`
  query BlogDetailsQuery($slugId:String!) {
    blogDetail(slugId: $slugId) {
      id
      createdAt
      updatedAt
      title
      context
      kind
      sortOrder
      image
      imageUrl
      slug
    }
  }
`;

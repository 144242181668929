/* eslint-disable no-undef */
const PROD = {
  BASE_URL: "https://api.revli.com", // production api
  BASE_URL_FRONT_END: "https://app.revli.com", //production app
};

//QA
const DEV = {
  BASE_URL: "https://ap1qa.revli.com",
  BASE_URL_FRONT_END: "https://staging.revli.com",
};

const LOCAL = {
  BASE_URL: "http://localhost:8000",
  BASE_URL_FRONT_END: "http://localhost:3000",
};

const MAP = {
  production: PROD,
  development: DEV,
  local: LOCAL,
};

let Config = MAP[process.env.REACT_APP_MODE];

export default Config;

import React, { useEffect, useState } from "react";
import iconTelegram from "../../assets/images/icons/icon-telegram.svg";
import iconConversation from "../../assets/images/icons/icon-conversation.svg";
import iconBarGraph from "../../assets/images/icons/icon-bar-graph.svg";
import iconSecurity from "../../assets/images/icons/icon-security.svg";
import iconInbox from "../../assets/images/icons/icon-inbox.svg";
import iconRocket from "../../assets/images/icons/icon-rocket.svg";

const SpamTestPagefeaturesListData = [
  {
    img: iconTelegram,
    title: "Improve your domain reputation and deliverability",
    subTitle:
      "Check your deliverability across dozens of parameters. Identify and fix any issues affecting your score. ",
  },
  {
    img: iconBarGraph,
    title: "Avoid spam filters and domain blacklists",
    subTitle:
      "Test and monitor your domain across 100+ different email blacklists and spam filters. ",
  },
  {
    img: iconConversation,
    title: "Boost open rates, click rates and reply rates ",
    subTitle:
      "Improve positive engagement and interactions across your email campaigns.",
  },
  {
    img: iconSecurity,
    title: "Optimize your content to improve your emails",
    subTitle:
      "Analyze your emails for risky content and spam words that can raise red flags and decrease deliverability. ",
  },
  {
    img: iconInbox,
    title: "Get insights and action steps to increase your sender score",
    subTitle:
      "Get an instant report of any issues affecting your deliverability and action steps on how to fix them.",
  },
  {
    img: iconRocket,
    title: "Skyrocket the results of your cold email campaigns",
    subTitle:
      "Drive more opens, clicks and replies. Supercharge your campaigns with cold emails that deliver results. ",
  },
];

const SpamTestPageFeatures = () => {
  const [width, setWindowWidth] = useState(0);
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  return (
    <section className="features-section pt-[7rem] pb-[3.875rem]">
      <div className="container mx-auto">
        {width < 640 ? (
          <h2 className="w-full xl:w-7/12 heading-level2 text-center mx-auto">
            Stop missing your prospects due to spam
          </h2>
        ) : (
          <h1 className="w-full xl:w-7/12 heading-level2 text-center mx-auto">
            Stop missing your prospects
            <br />
            due to spam
          </h1>
        )}
        <div className="scroll-x-wrapper">
          <ul className="features-section__list-wrapper mt-16">
            {SpamTestPagefeaturesListData.map((item, index) => (
              <li key={index} className="features-section__list">
                <div className="features-section__list__container">
                  <figure className="features-section__list__icon-wrapper">
                    <img src={item.img} alt="icon-telegram" />
                  </figure>
                  <h5 className="features-section__list__title">
                    {item.title}
                  </h5>
                  <p className="features-section__list__desc">
                    {item.subTitle}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SpamTestPageFeatures;

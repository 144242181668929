import React, { useState, useEffect, useRef } from "react";
import iconClose from "../../assets/images/icons/icon-close.svg";
import {
  copyToClipBoard,
  isEmpty,
  generateEmailFromUniqueCode,
  generateUniqueString,
} from "../../utils";
import LoaderImage from "../LoaderImage";
import {
  // fetchQuery,
  mutate,
} from "../../relay";
// import { GenerateUniqueEmailCodeQuery } from "../../service/query/GenerateUniqueEmailCode";
import { GetSpamResultMutation } from "../../service/mutations/GetSpamResultMutation";
// import { GetSpamCheckerResultQuery } from "../../service/query/GetSpamCheckerResultQuery";
import { GetSpamcheckerInboxReportsMutation } from "../../service/mutations/GetSpamcheckerInboxReportsMutation";

import Config from "../../config";
// import { showToaster } from "../../utils";

const TIME_OUT = 1.5 * 60 * 1000;
const INTERVAL = 4000;

const ModalRunNewTest = ({ openModal, setOpenModal }) => {
  const [emailSent, setEmailSent] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [uniqueCode, setUniqueCode] = useState("");
  const [uniqueCodeEmail, setUniqueCodeEmail] = useState("");
  const timeTaken = useRef({ value: 0 });

  const loadInboxData = () => {
    const input = {
      uniqueCode: uniqueCode,
    };
    mutate({
      mutation: GetSpamcheckerInboxReportsMutation,
      input: input,
      onSuccess: (data) => {
        if (!isEmpty(data?.getSpamcheckerInboxReports?.response)) {
          window.location = `${Config.BASE_URL_FRONT_END}/spam-checker-report?uniqueCode=${uniqueCode}`;
          setTimeout(() => {
            handleClose();
          }, INTERVAL / 2);
        } else {
          if (timeTaken.current.value < TIME_OUT) {
            setTimeout(() => {
              timeTaken.current.value = timeTaken.current.value + INTERVAL;
              loadInboxData();
            }, INTERVAL);
          } else {
            setShowErrorMessage(true);
          }
        }
      },
      onErrors: (error) => {
        console.error(error);
      },
    });

    // fetchQuery(GetSpamCheckerResultQuery, { uniqueCode: uniqueCode })
    //   .toPromise()
    //   .then((data) => {
    //     if (!isEmpty(data?.getSpamCheckerResult?.inbox?.id)) {
    //       window.location = `${Config.BASE_URL_FRONT_END}/spam-checker-report?uniqueCode=${uniqueCode}`;
    //       setTimeout(() => {
    //         handleClose();
    //       }, INTERVAL / 2);
    //     } else {
    //       console.log(timeTaken.current.value);
    //       if (timeTaken.current.value < TIME_OUT) {
    //         setTimeout(() => {
    //           timeTaken.current.value = timeTaken.current.value + INTERVAL;
    //           loadInboxData();
    //         }, INTERVAL);
    //       } else {
    //         setShowErrorMessage(true);
    //       }
    //     }
    //   });
  };

  const handleSendEmail = () => {
    if (!isEmpty(uniqueCode)) {
      setEmailSent(true);
      const input = { uniqueCode: uniqueCode };
      mutate({
        mutation: GetSpamResultMutation,
        input: input,
        onSuccess: (data) => {
          if (data?.getSpamCheckerResult?.success) {
            loadInboxData();
          } else {
            setShowErrorMessage(true);
          }
        },
        onErrors: (error) => {
          setShowErrorMessage(true);
          console.log(error);
        },
      });
    }
  };

  const generateUniqueCode = () => {
    const uniqueCode = generateUniqueString(15);
    const uniqueCodeEmail = generateEmailFromUniqueCode(uniqueCode);
    setUniqueCodeEmail(uniqueCodeEmail);
    setUniqueCode(uniqueCode);
    // fetchQuery(GenerateUniqueEmailCodeQuery, {})
    //   .toPromise()
    //   .then((data) => {
    //     if (data?.getMailUniqueCode) {
    //       const uniqueCodeEmail = data?.getMailUniqueCode?.email;
    //       setUniqueCodeEmail(uniqueCodeEmail);
    //       setUniqueCode(uniqueCodeEmail.split("+")[1].split("@")[0]);
    //     }
    //   })
    //   .catch((err) => {
    //     // showToaster("error", "Something went wrong. Please try again.");
    //     console.log(err);
    //   });
  };

  useEffect(() => {
    if (openModal) {
      generateUniqueCode();
    }
    // eslint-disable-next-line
  }, [openModal]);

  const handleClose = () => {
    setEmailSent(false);
    setShowErrorMessage(false);
    setOpenModal(false);
    timeTaken.current.value = 0;
  };

  return (
    <section
      className={"modal modal--run-new-test " + (openModal ? "show" : "")}
    >
      <div className="modal__container">
        <div className="modal__header py-8 pb-3 px-8">
          <h2 className="text-22 font-bold pr-5 flex-1">Run New Test</h2>
          {(!emailSent || showErrorMessage) && (
            <img
              src={iconClose}
              alt="icon-close"
              className="cursor-pointer"
              onClick={handleClose}
            />
          )}
        </div>
        <div className="modal__body px-8 pb-8 mt-4">
          {!emailSent ? (
            <div>
              <div>
                <p className="text-tertiary">
                  <strong className="mr-1">Step 1:</strong>Send your email to
                  this address:
                </p>
                <div className="flex flex gap-3 items-center my-2">
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="input-field input-field--sm mr-3"
                    value={uniqueCodeEmail}
                    readOnly
                  />
                  <button
                    className="btn btn--default btn--sm"
                    onClick={() =>
                      copyToClipBoard(uniqueCodeEmail, "Email address copied")
                    }
                  >
                    Copy
                  </button>
                </div>
              </div>
              <div className="flex items-center gap-4 mt-12">
                <button
                  className="btn btn--primary btn--sm"
                  onClick={handleSendEmail}
                  // disabled={isEmpty(uniqueCode)}
                >
                  <strong className="mr-1">Step 2:</strong> Get your score
                </button>
                <button
                  className="btn btn--default btn--sm"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : !showErrorMessage ? (
            <div className="my-12 px-10">
              <div className="mx-auto">
                <LoaderImage />
              </div>
              <h1 className="text-26 font-bold mt-12">
                We are processing your test results.
              </h1>
              <p className="text-secondary mt-3">
                Results usually load between 30-60 seconds. After 60 seconds, if
                nothing has loaded:
              </p>
              <div
                className="action-steps-wrapper ml-15 mt-6 text-secondary "
                style={{ marginLeft: "1rem" }}
              >
                <ol>
                  <li>
                    Are you certain you entered the correct email address?
                  </li>
                  <li>Are you certain your email has been sent?</li>
                </ol>
              </div>
            </div>
          ) : (
            <div className="my-12 px-10">
              <h1 className="text-26 font-bold mt-5">
                Looks like email wasn{"'"}t received
              </h1>
              <p className="text-secondary mt-3">Please try again.</p>
              <div className="mt-12">
                <button
                  className="btn btn--primary btn--sm"
                  onClick={() => {
                    generateUniqueCode();
                    setEmailSent(false);
                    setShowErrorMessage(false);
                    timeTaken.current.value = 0;
                  }}
                >
                  Try Again
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ModalRunNewTest;

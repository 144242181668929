import React from "react";
import iconArrowDown from "../assets/images/icons/icon-arrow-down.svg";

const AccordionItem = ({ faq, onToggle, active }) => {
  const { text, answer } = faq;
  return (
    <li
      className={`accordion__item ${active ? "active" : ""}`}
      onClick={onToggle}
    >
      <div className="accordion__header">
        <h4 className="accordion__title"> {text}</h4>
        <img
          src={iconArrowDown}
          alt="icon-arrow-down"
          className="accordion__arrow-icon"
        />
      </div>
      <div className="accordion__body">
        <p
          className="accordion__content"
          dangerouslySetInnerHTML={{ __html: answer }}
        ></p>
      </div>
    </li>
  );
};

export default AccordionItem;

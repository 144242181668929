import React from "react";
import getStartedPic from "../assets/images/get-started-pic.png";

const GetStarted = () => {
  return (
    <section className="get-started">
      <div className="main-container">
        <div className="text-center">
          <h2 className="text-white text-3xl md:text-4xl lg:text-[2.875rem] font-extrabold line-height3 text-center">
            Crush your sales quota <br className="hidden lg:block"></br>with
            Revli
          </h2>
          <p className="text-[#D7E7F6] md:text-lg mt-8">
            Supercharge your sales efforts and start closing more deals today.
          </p>
          <div className="flex flex-col items-center">
            <button
              className="btn btn--white py-2 px-8 mt-4 font-bold mx-auto"
              style={{ color: "#1D5AB5" }}
              onClick={() =>
                (window.location.href = "https://app.revli.com/register")
              }
            >
              Get Started Free
            </button>
            <span className="secondary-font text-white text-xs font-extrabold letter-space02 mt-1">
              No credit card required.
            </span>
          </div>
        </div>
        <img
          src={getStartedPic}
          alt="get-started-pic"
          width={853}
          height={330}
          className="mt-8 mx-auto"
        />
      </div>
    </section>
  );
};

export default GetStarted;

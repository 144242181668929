import React, { useEffect, useState } from "react";
// import articlePic1 from "../assets/images/article-pic1.jpg";
// import articlePic2 from "../assets/images/article-pic2.jpg";
// import articlePic3 from "../assets/images/article-pic3.jpg";
import { fetchQuery } from "../relay";
import { BlogQuery } from "../service/query/BlogQuery";
import CHOICES from "../CHOICES";
import { useNavigate } from "react-router-dom";

const LatestArticles = ({
  article = "Latest Articles",
  subhead = "Advice on deliverability, cold emailing and more.",
  articleKind = CHOICES.ArticleKind.LATEST_ARTICLES,
}) => {
  const [latestArticle, setLatesArticle] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchQuery(BlogQuery, { articlesKind: articleKind })
      .toPromise()
      .then((data) => {
        if (data.blogArticles) {
          setLatesArticle(data.blogArticles);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="container mx-auto">
      <h2 className="text-3xl md:text-4xl lg:text-[2.75rem] font-extrabold line-height3">
        {article}
      </h2>
      <p className="text-secondary text-base md:text-lg lg:text-xl mt-1">
        {subhead}
      </p>
      <div className="mt-10 lg:mt-20">
        <ul className="flex items-start -mx-[0.938rem] sm:flex-wrap overflow-x-auto sm-overflow-x-initial scrollbar-none overflow-x-behaviour-contain">
          {latestArticle.map((item, index) => (
            <li
              key={index}
              className="articles-list flex-shrink-0 w-11/12 sm:w-6/12 lg:w-4/12 xl:w-3/12 sm:mb-6 px-[0.938rem]"
            >
              <div
                className="articles-list__container w-full cursor-pointer"
                onClick={() => navigate(`/blog/${item.slug}`)}
              >
                <figure className="articles-list__pic-wrapper">
                  <img src={item.imageUrl} alt="articles-pic" />
                </figure>
                <div className="mt-2 md:mt-4">
                  {/* <div className="">
                      <span className="text-secondary text-sm italic">in </span>
                      <span className="tex-sm text-primary-brand-dark font-medium">
                        Revli
                      </span>
                    </div> */}
                  <h3 className="text-[1.375rem] font-bold line-clamp-2">
                    {item.title}
                  </h3>
                  {/* <div className="flex items-center w-full mt-2">
                      <span className="text-secondary text-sm font-medium">
                        {item.by}&nbsp;
                      </span>
                      <span className="text-secondary-light text-sm italic">
                        by {item.days}
                      </span>
                    </div> */}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LatestArticles;

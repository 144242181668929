/**
 * @generated SignedSource<<b62c16a73a34ee172e5e9ed5425c5e54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "kind"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "kind",
        "variableName": "kind"
      }
    ],
    "concreteType": "DynamicContentType",
    "kind": "LinkedField",
    "name": "getContent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "kind",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "context",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetDynamicContentQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetDynamicContentQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "795abb65fb00272b458680b51b11be7f",
    "id": null,
    "metadata": {},
    "name": "GetDynamicContentQuery",
    "operationKind": "query",
    "text": "query GetDynamicContentQuery(\n  $kind: Int!\n) {\n  getContent(kind: $kind) {\n    kind\n    context\n    title\n    id\n  }\n}\n"
  }
};
})();

node.hash = "8d5799bbbd410200bf79abcb3b71480b";

module.exports = node;

import React from "react";
import polygonBg from "../../assets/images/polygon-bg.svg";
import { isEmpty } from "../../utils";

const HeroCommon = ({ title = "", subTitle = "" }) => {
  return (
    <section className="hero-common">
      <div className="relative container mx-auto z-[2] mt-12 lg:mt-20 xl:mt-24">
        <h1 className="heading-level1">{title}</h1>

        {!isEmpty(subTitle) && (
          <p className="hero-common__sub-title">{subTitle}</p>
        )}
      </div>
      <img
        src={polygonBg}
        alt="polygon-bg"
        className="absolute top-[4.25rem] left-0 xxl:right-0 xxl:mx-auto"
      />
    </section>
  );
};

export default HeroCommon;

import md5 from "md5";
import { toast } from "react-toastify";
// var truncate = require('truncate-html')
import truncate from "truncate-html";

export const isEmpty = (val) => {
  // Stolen From: https://stackoverflow.com/a/28953167
  /*
    test results
    --------------
    []        true, empty array
    {}        true, empty object
    null      true
    undefined true
    ""        true, empty string
    ''        true, empty string
    0         false, number
    true      false, boolean
    false     false, boolean
    Date      false
    function  false
    */
  if (val === undefined) return true;
  if (
    typeof val == "function" ||
    typeof val == "number" ||
    typeof val == "boolean" ||
    Object.prototype.toString.call(val) === "[object Date]"
  )
    return false;
  if (val == null || val.length === 0)
    // null or 0 length array
    return true;
  if (typeof val == "object") if (Object.keys(val).length === 0) return true;
  return false;
};

export const truncateHtml = (html, length) => {
  return truncate(html, length, { byWords: true });
};

export const validEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isValidEmail = (email) => {
  return validEmail.test(email);
};

export const copyToClipBoard = (
  textToCopy,
  successText = "Copied Successfully!"
) => {
  navigator.clipboard.writeText(textToCopy).then(() => {
    showToaster("success", successText);
  });
};

export const showToaster = (eventType, eventMess) => {
  switch (eventType.toLowerCase()) {
    case "success":
      toast.success(eventMess, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      break;

    case "warn":
      toast.warn(eventMess, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      break;

    case "info":
      toast.info(eventMess, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      break;

    case "error":
      toast.error(eventMess, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      break;

    default:
      toast(eventMess, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      break;
  }
};

export const generateUniqueString = (length = 12) => {
  let dateString = Date.now().toString(16);
  let string = (Math.random() + 1).toString(16).substring(7);
  const uniqueCode = "rwu-" + md5(string + dateString).substring(length);
  return uniqueCode;
};

export const generateEmailFromUniqueCode = (uniqueCode) => {
  return `email+${uniqueCode}@revli-test.com`;
};





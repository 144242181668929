import { graphql } from 'babel-plugin-relay/macro';

export const FaqQuery = graphql`
  query FaqQuery($searchKey: String, $topic:Int!) {
    faq(searchKey: $searchKey, topic: $topic) {
      id
      createdAt
      updatedAt
      text
      answer
      isActive
      sortOrder
    }
  }
`;

import React, { useEffect, useState } from "react";
import polygonBg from "../../assets/images/polygon-bg2.svg";

const SpamTestHero = ({ setOpenModal }) => {
  const [width, setWindowWidth] = useState(0);
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  return (
    <section className="bg-hero pt-[7rem] sm:pt-[10rem] lg:pt-[13.5rem] pb-[9rem] lg:pb-[11.25rem]">
      <div className="relative container mx-auto z-[1]">
        <h1 className="text-white text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold md-line-height3 text-center">
          Turn insights into actions <br className="hidden lg:block"></br>
          that drive results
        </h1>
        <div className="flex flex-col items-center">
          <button
            onClick={setOpenModal}
            className="btn btn--primary mt-10 mx-auto py-3 w-full sm:w-auto px-8 sm:px-20 lg:text-[1.375rem] text-lg"
          >
            Run Email Spam Test
          </button>
          <p className="text-white text-center text-[0.813rem] font-bold mt-4">
            100% free. No sign-up required.
          </p>
        </div>
      </div>
      <img src={polygonBg} alt="" className="spam-bg-image" />
    </section>
  );
};

export default SpamTestHero;

import React, { useEffect, useState } from "react";
// import Header from "../components/Header";
import LatestArticles from "../components/LatestArticles";
import Footer from "../components/Footer";
import { fetchQuery } from "../relay";
import { BlogQuery } from "../service/query/BlogQuery";
import CHOICES from "../CHOICES";
import { isEmpty, truncateHtml } from "../utils";
import { useNavigate } from "react-router-dom";
import HeaderNew from "../components/HeaderNew";

const Blogs = () => {
  const [coverArticles, setCoverArticles] = useState([]);
  const [popularArticles, setPopularArticles] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchQuery(BlogQuery, { articlesKind: CHOICES.ArticleKind.COVER_ARTICLES })
      .toPromise()
      .then((data) => {
        if (data.blogArticles) {
          if (data.blogArticles.length >= 3) {
            const tempArr = data.blogArticles.slice(0, 3);
            setCoverArticles(tempArr);
          }
        }
      });

    fetchQuery(BlogQuery, { articlesKind: CHOICES.ArticleKind.POPULAR_POSTS })
      .toPromise()
      .then((data) => {
        if (data.blogArticles) {
          setPopularArticles(data.blogArticles);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <main className="blog">
      <HeaderNew />
      {/* <Header className="header-light" /> */}
      <section className="blog__container">
        <section className="blog__recent-post">
          <div className="main-container">
            <div className="flex items-start justify-between flex-wrap lg:flex-nowrap lg:-mx-8">
              {!isEmpty(coverArticles) && (
                <div
                  onClick={() => navigate(`/blog/${coverArticles[0].slug}`)}
                  className="w-full lg:w-7/12 lg:px-8 mb-10 lg:mb-0 cursor-pointer"
                >
                  <figure className="blog__post-pic blog__post-pic--lg">
                    <img src={coverArticles[0].imageUrl} alt="blog-pic" />
                  </figure>
                  <div className="mt-4 lg:mt-8">
                    <h1 className="blog__title blog__title--lg">
                      {coverArticles[0].title}
                    </h1>
                    <p
                      className="blog__content blog__content--lg mt-4 lg:mt-12"
                      dangerouslySetInnerHTML={{
                        __html: truncateHtml(coverArticles[0].context, 50),
                      }}
                    ></p>
                  </div>
                </div>
              )}
              <div className="w-full lg:w-5/12 lg:px-8 cursor-pointer">
                {!isEmpty(coverArticles) && (
                  <div
                    className=""
                    onClick={() => navigate(`/blog/${coverArticles[1].slug}`)}
                  >
                    <figure className="blog__post-pic blog__post-pic--sm">
                      <img src={coverArticles[1].imageUrl} alt="blog-pic" />
                    </figure>
                    <div className="mt-4">
                      <h2 className="blog__title blog__title--md">
                        {coverArticles[1].title}
                      </h2>
                    </div>
                  </div>
                )}
                {!isEmpty(coverArticles) && (
                  <div
                    className="mt-20 cursor-pointer"
                    onClick={() => navigate(`/blog/${coverArticles[2].slug}`)}
                  >
                    <figure className="blog__post-pic blog__post-pic--sm">
                      <img src={coverArticles[2].imageUrl} alt="blog-pic" />
                    </figure>
                    <div className="mt-4">
                      <h2 className="blog__title blog__title--md">
                        {coverArticles[2].title}
                      </h2>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
      <div className="">
        <LatestArticles />
      </div>
      <section className="blog__popular-post">
        <div className="main-container">
          <div className="text-center">
            <h2 className="blog__popular-post__heading">Popular Posts</h2>
            <p className="text-base lg:text-xl font-medium text-secondary-light mt-1">
              Updates and insights to move your sales forward.
            </p>
          </div>

          <ul className="blog__popular-post__list-wrapper">
            {popularArticles.map((article, index) => (
              <li
                key={`${index}`}
                className="blog__popular-post__list cursor-pointer"
                onClick={() => navigate(`/blog/${article.slug}`)}
              >
                <figure className="blog__popular-post__pic">
                  <img src={article.imageUrl} alt="popular-post-pic" />
                </figure>
                <div className="mt-5 lg:ml-8 xl:ml-16 xl:mt-0">
                  <h3 className="blog__title--sm mt-2 xl:mt-0">
                    {article.title}
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: truncateHtml(article.context, 20),
                    }}
                    className="text-secondary font-medium mt-8 letter-space02 opacity-80"
                  ></p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default Blogs;

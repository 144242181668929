import graphql from "babel-plugin-relay/macro";

export const GetSpamcheckerInboxReportsMutation = graphql`
  mutation GetSpamcheckerInboxReportsMutation(
    $input: GetSpamcheckerInboxReportsMutationInput!
  ) {
    getSpamcheckerInboxReports(input: $input) {
      response {
        checksMade {
          id
          score
          viewType
          kind
          testSummary
        }
        inbox {
          id
          subject
          score
          bodyMessage
          bodyHtml
          createdAt
          email
          kind
          refreshTotalScore
        }
        contentAnalysis {
          id
          score
          testSummary
          viewType
          kind
        }
        emailPlacement {
          id
          score
          testSummary
          viewType
          kind
          breakdown {
            gmail {
              gmailBusinessReceived
              gmailBusinessSend
              gmailBusinessReceivedSpam
              gmailPersonalReceived
              gmailPersonalSend
              gmailPersonalReceivedSpam
            }
            office365 {
              office365BusinessReceived
              office365BusinessSend
              office365BusinessReceivedSpam
              office365PersonalReceived
              office365PersonalSend
              office365PersonalReceivedSpam
            }
            zoho {
              zohoPersonalReceived
              zohoPersonalSend
              zohoPersonalReceivedSpam
            }
            yahoo {
              yahooPersonalReceived
              yahooPersonalSend
              yahooPersonalReceivedSpam
            }
          }
        }
      }
    }
  }
`;

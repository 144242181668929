import React, { useEffect, useState } from "react";
// import { faqs } from "./data";
import AccordionItem from "./AccordionItem";
import { fetchQuery } from "../relay";
import { FaqQuery } from "../service/query/FaqQuery";

const Accordion = ({ search, topic }) => {
  const [clicked, setClicked] = useState("0");
  const [faqs, setFaqs] = useState([]);
  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0");
    }
    setClicked(index);
  };

  useEffect(() => {
    fetchQuery(FaqQuery, { searchKey: search, topic: topic })
      .toPromise()
      .then((data) => {
        if (data.faq) {
          setFaqs(data.faq);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search,topic]);

  return (
    <ul className="accordion">
      {faqs.map((faq, index) => (
        <AccordionItem
          key={index}
          faq={faq}
          onToggle={() => handleToggle(index)}
          active={clicked === index}
        />
      ))}
    </ul>
  );
};

export default Accordion;

import React from "react";
import iconLoader from "../assets/images/icons/icon-loader.svg";

const LoaderImage = ({ size = "medium" }) => {
  return (
    <img
      src={iconLoader}
      alt="icon-loader"
      className={`mx-auto loader-image ${size && `loader-image__${size}`}`}
    />
  );
};

export default LoaderImage;

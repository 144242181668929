import React from "react";
import timeResources from "../assets/images/icons/time-resources.svg";
import telegram from "../assets/images/icons/telegram.svg";
import postBox from "../assets/images/icons/post-box.svg";
import focusedApproach from "../assets/images/icons/focused-approach.svg";

const AboutUsValues = () => {
  const aboutUsValuesData = [
    {
      img: telegram,
      title: "Be Innovative",
      desc: "We champion innovation, pushing boundaries to deliver fresh, impactful solutions that keep us ahead of the curve.",
    },
    {
      img: focusedApproach,
      title: "Product First",
      desc: "Our 'product first' approach ensures we prioritize quality and user experience, building solutions that truly make a difference.",
    },
    {
      img: timeResources,
      title: "Teamwork",
      desc: "Our success is built on teamwork, fostering open communication and mutual respect to achieve common goals.",
    },
    {
      img: postBox,
      title: "Move Fast",
      desc: "We value speed and agility, enabling us to respond quickly to market changes and seize opportunities swiftly.",
    },
  ];
  return (
    <section className="py-10 lg:py-20">
      <div className="container mx-auto">
        <h3 className="text-primary-brand-dark text-2xl lg:text-3xl font-bold text-center">
          Our values
        </h3>
        <h2 className="text-[#1D314E] text-3xl lg:text-[2.5rem] font-bold text-center mt-4">
          Strong values that bring great people together
        </h2>
        <div className="mt-12 lg:mt-[7rem] relative">
          <ul className="flex md:flex-wrap -mx-3 pt-6 md:pt-0 overflow-x-auto md-overflow-x-initial scrollbar-none overflow-x-behaviour-contain">
            {aboutUsValuesData.map((item, index) => (
              <li
                key={index}
                className="flex-shrink-0 w-11/12 md:w-6/12 px-3 mb-6"
              >
                <div className="h-full card-box">
                  <figure className="fig-wrapper">
                    <img src={item.img} alt="icon-telegram" />
                  </figure>
                  <div className="flex flex-col mt-5">
                    <h5 className="text-xl font-bold">{item.title}</h5>
                    <p className="text-secondary text-lg letter-space02 mt-4">
                      {item.desc}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AboutUsValues;

import React from "react";
import Footer from "../components/Footer";
import HeaderNew from "../components/HeaderNew";
import FAQ from "../components/FAQ";
import CHOICES from "../CHOICES";
import Testimonials from "../components/Testimonials";
import GetStarted from "../components/GetStarted";
import aboutUsBanner from "../assets/images/about-us-banner.webp";
import AboutUsValues from "../components/AboutUsValues";
import AboutUsFeatures from "../components/AboutUsFeatures";
import teamPic1 from "../assets/images/teampic1.webp";
import teamPic2 from "../assets/images/teampic2.webp";
import ellipseHeroPic from "../assets/images/hero-bg-ellipse.svg";

const AboutUs = () => {
  const statisticsData = [
    {
      id: 1,
      count: "$78",
      title: "Billion in Funding Data",
      desc: "We've analyzed over $78 billion in startup funding, providing key insights that drive growth across industries.",
    },
    {
      id: 2,
      count: "6000+",
      title: "Active Inboxes",
      desc: "With over 6000 active inboxes, our warmup network guarantees top deliverability and engagement rates.",
    },
    {
      id: 3,
      count: "20+",
      title: "Countries With Clients",
      desc: "Our reach extends across more than 20 countries, offering a blend of local expertise and global perspectives.",
    },
  ];
  const statisticsData2 = [
    {
      id: 1,
      count: "10",
      title: "Years Experience",
      desc: "Leveraging a decade of industry experience, we deliver proven solutions that enhance your business strategy.",
    },
    {
      id: 2,
      count: "8",
      title: "Team Members",
      desc: "Our dedicated team of 8 experts specializes in transformative marketing and technology solutions.",
    },
    {
      id: 3,
      count: "95%",
      title: "Customer Satisfaction",
      desc: "With a 95% satisfaction rate, our clients rely on us for exceptional results and committed service.",
    },
  ];

  return (
    <main className="">
      <section className="meeting-schedule">
        <HeaderNew />
        <section className="bg-hero pt-[7rem] sm:pt-[10rem] lg:pt-[13.625rem] pb-16 lg:pb-10">
          <div className="relative container mx-auto flex flex-col lg:flex-row z-[1]">
            <div className="w-full lg:w-6/12 pr-10">
              <h1 className="text-white text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold lg-line-height2">
                It's our business
                <br></br>
                to grow yours.
              </h1>
              <p className="text-white text-base md:text-xl mt-4 letter-space02 opacity-70">
                At Revli, we partner with you to accelerate your growth through
                tailored strategies and cutting-edge technology.
              </p>
            </div>
            <figure className="relative w-full lg:w-6/12 lg:-top-[3.438rem] mt-8 lg:mt-0">
              <img
                src={aboutUsBanner}
                alt="about-us-banner"
                className="max-w-full lg:max-w-[50rem] rounded-2xl"
              />
            </figure>
          </div>
          <img
            src={ellipseHeroPic}
            alt="ellipse-hero-bg"
            className="absolute top-0 right-0 h-3/4"
          />
        </section>
        <section className="pt-16 lg:pt-20 pb-[3.875rem] lg:pb-[7.75rem] overflow-x-auto lg-overflow-x-initial scrollbar-none overflow-x-behaviour-contain">
          <div className="container mx-auto min-w-[75rem] lg:min-w-auto">
            <div className="flex items-start pb-3 border-b border-border-color">
              {statisticsData.map((item, index) => (
                <div key={index} className="w-4/12 p-6 lg:p-10">
                  <h2 className="text-[#1E5AB6] text-4xl lg:text-[1.875rem] font-bold">
                    {item.count}
                  </h2>
                  <h4 className="text-lg lg:text-xl font-bold mt-2">
                    {item.title}
                  </h4>
                  <p className="text-secondary letter-space02 mt-4">
                    {item.desc}
                  </p>
                </div>
              ))}
            </div>
            <div className="flex items-start mt-3">
              {statisticsData2.map((item, index) => (
                <div key={index} className="w-4/12 p-10">
                  <h2 className="text-[#1E5AB6] text-4xl lg:text-[1.875rem] font-bold">
                    {item.count}
                  </h2>
                  <h4 className="text-lg lg:text-xl font-bold mt-2">
                    {item.title}
                  </h4>
                  <p className="text-secondary letter-space02 mt-4">
                    {item.desc}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
        <AboutUsValues />
        <section className="py-[3.875rem] lg:py-[7.5rem]">
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row px-10 pt-10 pb-10 lg:pb-4 bg-white shadow-[0px_0px_120px_0px_#DEE5EC] rounded-[1.25rem]">
              <div className="w-full lg:w-5/12">
                <h2 className="text-black text-2xl lg:text-[2.5rem] font-bold lg-line-height2">
                  A great story starts with a great team
                </h2>
                <div className="mt-6">
                  <p className="text-lg lg:text-xl letter-space02">
                    Join Revli and be part of a global community of marketing,
                    sales and tech experts dedicated to setting industry
                    standards and driving innovation.
                  </p>
                  <p className="text-lg lg:text-xl letter-space02 mt-5">
                    Dive into a culture where your ideas have a direct impact
                    and where every contribution shapes the future of businesses
                    worldwide.
                  </p>
                </div>

                <button
                  className="btn btn--primary btn--h52 w-full lg:w-6/12 text-lg lg:text-xl font-bold px-8 py-2 mt-8 lg:mt-12"
                  onClick={() => {
                    window.open(
                      "https://wellfound.com/company/revli",
                      "_blank"
                    );
                  }}
                >
                  Join Us
                </button>
              </div>
              <div className="hidden relative w-full lg:w-6/12 md:flex lg:pl-10 xl:pl-[7rem] mt-8 lg:mt-0">
                <img
                  src={teamPic1}
                  alt="team-pic"
                  className="max-h-[27.563rem]"
                />
                <img
                  src={teamPic2}
                  alt="team-pic"
                  className="max-h-[29.156rem]"
                  style={{ marginTop: 68, marginLeft: "-55px" }}
                />
              </div>
            </div>
          </div>
        </section>
        <AboutUsFeatures />
        <section className="pt-[3.875rem] pb-[7rem]">
          <FAQ topic={CHOICES.FAQTopic.ABOUT_US} />
        </section>
        <Testimonials />
        <GetStarted />
        <Footer />
      </section>
    </main>
  );
};

export default AboutUs;

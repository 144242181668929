import React, { useEffect, useState } from "react";
// import Header from "../components/Header";
import Footer from "../components/Footer";
import { fetchQuery } from "../relay";
import { BlogDetailsQuery } from "../service/query/BlogDetailsQuery";
import { useParams } from "react-router-dom";
import HeaderNew from "../components/HeaderNew";

const BlogsDetails = () => {
  const [article, setArticle] = useState({});
  const { id } = useParams();
  useEffect(() => {
    fetchQuery(BlogDetailsQuery, { slugId: id })
      .toPromise()
      .then((data) => {
        setArticle(data.blogDetail);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <main className="blog">
      {/* <Header className="header-light" /> */}
      <HeaderNew />
      <section className="blog__container">
        <section className="py-20">
          <div className="main-container">
            <div className="w-full md:w-10/12 lg:w-9/12 mx-auto">
              <figure className="blog__post-pic blog__post-pic--lg">
                <img src={article.imageUrl} alt="blog-pic" />
              </figure>
              <div className="mt-4 lg:mt-8">
                <h1 className="blog__title blog__title--lg">{article.title}</h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: article.context,
                  }}
                  className="blog__content blog__content--lg mt-4 lg:mt-12"
                ></p>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </main>
  );
};

export default BlogsDetails;

import React from "react";
import spamCheckSolutionsPic1 from "../../assets/images/spam-check-solutions-pic1.png";
import spamCheckSolutionsPic2 from "../../assets/images/spam-check-solutions-pic2.png";
import spamCheckSolutionsPic3 from "../../assets/images/spam-check-solutions-pic3.png";
import spamCheckSolutionsPic4 from "../../assets/images/spam-check-solutions-pic4.png";
import spamCheckSolutionsPic5 from "../../assets/images/spam-check-solutions-pic5.png";
import spamCheckSolutionsPic6 from "../../assets/images/spam-check-solutions-pic6.png";

const SpamTestHomeSolutionsListData = [
  {
    img: spamCheckSolutionsPic1,
    title: "Email deliverability analysis across all major inbox providers",
    subTitle:
      "Track your deliverability across all major inbox providers and find out in real-time if your emails are landing in the inbox or the spam folder.",
  },
  {
    img: spamCheckSolutionsPic2,
    title: "Blacklists, SPF, DKIM, DMARC, and SpamAssassian checks",
    subTitle:
      "Check whether or not your email passes SPF, DKIM & DMARC authentication and any blacklists your domain or email address may be on.",
  },
  {
    img: spamCheckSolutionsPic3,
    title: "Content analysis to optimize and improve your messaging",
    subTitle:
      "Analyze your email copy for risky content and spam words that can raise red flags and decrease deliverability with mailbox providers.",
  },
  {
    img: spamCheckSolutionsPic4,
    title: "HTML, links, subject line and attachments checks",
    subTitle:
      "Examine your email’s links, attachments, subject line and HTML complexity to identify and correct any issues before you send.",
  },
  {
    img: spamCheckSolutionsPic5,
    title: "Google and Outlook spam filter checks",
    subTitle:
      "Test your email across common spam filters, including Gmail, Google Workspace, Outlook and Office 365, to identify which email providers are marking you as spam.",
  },
  {
    img: spamCheckSolutionsPic6,
    title: "Insights and action steps to improve your deliverability",
    subTitle:
      "Review insights and recommendations with step-by-step instructions to improve your sender score and email deliverability.",
  },
];

const SpamTestHomeSolutions = ({ setOpenModal }) => {
  return (
    <section className="solutions-section solutions-section--spam-test">
      <div className="main-container">
        <h2 className="hidden md:block w-full heading-level2 leading-tight text-center mx-auto">
          Supercharge the success <br></br> of your cold email campaigns
        </h2>
        <h2 className="block md:hidden w-full heading-level2 leading-tight text-center mx-auto">
          Supercharge the success of your cold email campaigns
        </h2>

        <ul className="solutions-section__list-wrapper mt-16">
          {SpamTestHomeSolutionsListData.map((item, index) => (
            <li key={index} className="solutions-section__list">
              <div className="solutions-section__list__container">
                <figure className="solutions-section__list__icon-wrapper">
                  <img src={item.img} alt="icon-telegram" />
                </figure>
                <h5
                  className="solutions-section__list__title"
                  // style={{ marginTop: 0 }}
                >
                  {item.title}
                </h5>
                <p className="solutions-section__list__desc">{item.subTitle}</p>
              </div>
            </li>
          ))}
        </ul>

        <button
          className="btn btn--primary mt-10 mx-auto py-3 px-20 mb-5 text-22"
          onClick={() => setOpenModal(true)}
        >
          Run Email Spam Test
        </button>
        <p
          className="text-white text-center mb-5 text-20"
          style={{ color: "#000" }}
        >
          100% free. No sign-up required.
        </p>
      </div>
    </section>
  );
};

export default SpamTestHomeSolutions;

import React, { useEffect, useState } from "react";
import pipedirveLogo from "../assets/images/logos/pipedrive-logo.svg";
import spendeskLogo from "../assets/images/logos/spendesk-logo.svg";
import quizletLogo from "../assets/images/logos/quizlet-logo.svg";
import chargifyLogo from "../assets/images/logos/chargify-logo.svg";
import craftomLogo from "../assets/images/logos/craftom-logo.svg";
import alternativePaymentsLogo from "../assets/images/logos/alternative-payments-logo.svg";
import radViewLogo from "../assets/images/logos/radview-logo.svg";
import worcaLogo from "../assets/images/logos/worca-logo.svg";
import trustero from "../assets/images/logos/trustero-logo.svg";
import iconArrowLeft from "../assets/images/icons/icon-arrow-left.svg";
import iconArrowRight from "../assets/images/icons/icon-arrow-right.svg";
import iconQuote from "../assets/images/icons/icon-quotes.svg";
import { fetchQuery } from "../relay";
import { QuoteQuery } from "../service/query/QuoteQuery";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import "../assets/styles/scss/pages/testimonials.scss";

const Testimonials = () => {
  const [quote, setQuote] = useState([]);
  const [swipe, setSwipe] = useState(null);
  useEffect(() => {
    fetchQuery(QuoteQuery, {})
      .toPromise()
      .then((data) => {
        if (data.quotes) {
          setQuote(data.quotes);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const companyLogo = [
    pipedirveLogo,
    spendeskLogo,
    quizletLogo,
    chargifyLogo,
    craftomLogo,
    alternativePaymentsLogo,
    radViewLogo,
    worcaLogo,
    trustero,
  ];
  return (
    <section className="testimonials-section py-8 lg:py-20 bg-section3">
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl lg:text-[2.875rem] font-extrabold text-center line-height3">
          Helping global companies<br className="hidden lg:block"></br> and{" "}
          <span className="text-[#1F5BB6] inline-block"> growing startups</span>
        </h2>
        <div className="flex items-center -mx-5 my-12 lg:mt-[5.25rem] overflow-x-auto scrollbar-none overflow-x-behaviour-contain">
          {companyLogo.map((item, index) => (
            <div key={index} className="flex-shrink-0 px-5">
              <img
                src={item}
                alt="company-logos"
                style={{ maxHeight: "2.813rem" }}
              />
            </div>
          ))}
        </div>
      </div>

      {/* <div className="scroll-x-wrapper">
        <div className="main-container">
          <div className="customer-logo-wrapper">
            <div className="customer-logo__container">
              <img
                src={pipedirveLogo}
                alt="pipedriveLogo"
                style={{ height: "55px" }}
              />
            </div>
            <div className="customer-logo__container">
              <img
                src={spendeskLogo}
                alt="pipedriveLogo"
                style={{ height: "49px" }}
              />
            </div>
            <div className="customer-logo__container">
              <img
                src={quizletLogo}
                alt="pipedriveLogo"
                style={{ height: "42px" }}
              />
            </div>
            <div className="customer-logo__container">
              <img
                src={chargifyLogo}
                alt="pipedriveLogo"
                style={{ height: "43px" }}
              />
            </div>
          </div>
        </div>
      </div> */}

      {quote.length > 0 && (
        <div className="px-5 sm:px-0 testimonials-carousel-wrapper mt-8 md:mt-20">
          <div className="testimonials-carousel__container md:w-6/12 mx-auto">
            <Swiper onBeforeInit={(swipper) => setSwipe(swipper)}>
              {quote.map((qt, index) => (
                <SwiperSlide
                  key={`${index}`}
                  className="testimonials-carousel__content text-center"
                >
                  <img
                    src={iconQuote}
                    alt="icon-quote"
                    className="mx-auto h-10 md:h-auto"
                  />
                  <p className="text-[#22343F] text-lg md:text-2xl font-bold italic mt-4 md:mt-6 letter-space02">
                    {qt.text}
                  </p>
                  <div className="flex items-center justify-center mt-6">
                    <figure className="w-[6.5rem] h-[6.5rem] flex-shrink-0 bg-[#D9D9D9] rounded-full">
                      <img
                        src={qt?.imageUrl}
                        alt="profile"
                        className="w-full h-full object-cover rounded-full"
                      />
                    </figure>
                    <div className="flex flex-col ml-4">
                      <p className="md:text-xl font-bold">{qt.name}</p>
                      <p className="text-secondary">{qt.designation}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="testimonials-carousel__controls">
              <button
                onClick={() => swipe?.slidePrev()}
                className="btn-controls btn-controls--prev"
              >
                <img
                  src={iconArrowLeft}
                  alt="icon-arrow-left"
                  className="btn-controls__icon-arrow"
                />
              </button>
              <button
                onClick={() => swipe?.slideNext()}
                className="btn-controls btn-controls--next"
              >
                <img
                  src={iconArrowRight}
                  alt="icon-arrow-right"
                  className="btn-controls__icon-arrow"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Testimonials;

import React from "react";
import Accordion from "./Accordion";

const FAQ = ({ topic }) => {
  return (
    <div className="container mx-auto">
      <h2 className="text-2xl md:text-4xl lg:text-[2.75rem] font-extrabold text-left md:text-center lg-line-height2">
        Frequently Asked Questions
      </h2>
      <div className="w-full md:w-10/12 mx-auto mt-4 md:mt-16">
        <Accordion topic={topic} />
      </div>
    </div>
  );
};

export default FAQ;

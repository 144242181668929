/**
 * @generated SignedSource<<39b7339b81ba86af7115a1d5cf241acf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "testSummary",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GetSpamcheckerInboxReportsMutationPayload",
    "kind": "LinkedField",
    "name": "getSpamcheckerInboxReports",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SpamCheckerResultType",
        "kind": "LinkedField",
        "name": "response",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChecksMadeType",
            "kind": "LinkedField",
            "name": "checksMade",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TestInboxType",
            "kind": "LinkedField",
            "name": "inbox",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subject",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bodyMessage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bodyHtml",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refreshTotalScore",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChecksMadeType",
            "kind": "LinkedField",
            "name": "contentAnalysis",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v5/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "InboxEmailPlacementReport",
            "kind": "LinkedField",
            "name": "emailPlacement",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v5/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EmailPlacementBreakdown",
                "kind": "LinkedField",
                "name": "breakdown",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GmailTestType",
                    "kind": "LinkedField",
                    "name": "gmail",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gmailBusinessReceived",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gmailBusinessSend",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gmailBusinessReceivedSpam",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gmailPersonalReceived",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gmailPersonalSend",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gmailPersonalReceivedSpam",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Office365TestType",
                    "kind": "LinkedField",
                    "name": "office365",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "office365BusinessReceived",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "office365BusinessSend",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "office365BusinessReceivedSpam",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "office365PersonalReceived",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "office365PersonalSend",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "office365PersonalReceivedSpam",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ZohoTestType",
                    "kind": "LinkedField",
                    "name": "zoho",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "zohoPersonalReceived",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "zohoPersonalSend",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "zohoPersonalReceivedSpam",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "YahooTestType",
                    "kind": "LinkedField",
                    "name": "yahoo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "yahooPersonalReceived",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "yahooPersonalSend",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "yahooPersonalReceivedSpam",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetSpamcheckerInboxReportsMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetSpamcheckerInboxReportsMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "b1fdfc0fa174da5cdce02c71a710a121",
    "id": null,
    "metadata": {},
    "name": "GetSpamcheckerInboxReportsMutation",
    "operationKind": "mutation",
    "text": "mutation GetSpamcheckerInboxReportsMutation(\n  $input: GetSpamcheckerInboxReportsMutationInput!\n) {\n  getSpamcheckerInboxReports(input: $input) {\n    response {\n      checksMade {\n        id\n        score\n        viewType\n        kind\n        testSummary\n      }\n      inbox {\n        id\n        subject\n        score\n        bodyMessage\n        bodyHtml\n        createdAt\n        email\n        kind\n        refreshTotalScore\n      }\n      contentAnalysis {\n        id\n        score\n        testSummary\n        viewType\n        kind\n      }\n      emailPlacement {\n        id\n        score\n        testSummary\n        viewType\n        kind\n        breakdown {\n          gmail {\n            gmailBusinessReceived\n            gmailBusinessSend\n            gmailBusinessReceivedSpam\n            gmailPersonalReceived\n            gmailPersonalSend\n            gmailPersonalReceivedSpam\n          }\n          office365 {\n            office365BusinessReceived\n            office365BusinessSend\n            office365BusinessReceivedSpam\n            office365PersonalReceived\n            office365PersonalSend\n            office365PersonalReceivedSpam\n          }\n          zoho {\n            zohoPersonalReceived\n            zohoPersonalSend\n            zohoPersonalReceivedSpam\n          }\n          yahoo {\n            yahooPersonalReceived\n            yahooPersonalSend\n            yahooPersonalReceivedSpam\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "5e92e2c64bad763a80128b9e6401073c";

module.exports = node;

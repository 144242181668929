import React from "react";
import revliLogo from "../assets/images/logos/revli-logo.svg";
import iconLinkedin from "../assets/images/icons/icon-linkedin.svg";
import iconFacebook from "../assets/images/icons/icon-facebook.svg";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  const navigate = useNavigate();
  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "auto" });
  };
  return (
    <footer className="footer-section">
      <div className="container mx-auto">
        <div className="flex items-start flex-wrap justify-between">
          <div className="w-full lg:w-2/12 flex flex-col items-center lg:items-start flex-shrink-0">
            <img
              src={revliLogo}
              alt="revli-logo"
              style={{ width: 106, height: 26 }}
            />
            <div className="flex items-center gap-4 mt-5">
              <div
                className="social-media-link"
                onClick={() =>
                  (window.location.href =
                    "https://www.linkedin.com/company/getrevli")
                }
              >
                <img src={iconLinkedin} alt="icon-linkedin" />
              </div>
              <div
                className="social-media-link"
                onClick={() =>
                  (window.location.href = "https://twitter.com/RevliHQ")
                }
              >
                <img src={iconFacebook} alt="icon-linkedin" />
              </div>
            </div>
          </div>
          <ul className="w-full flex flex-col items-center lg:w-10/12 lg:items-start lg:justify-between lg:flex-row lg:flex-1">
            <li className="footer__list text-center lg:text-left">
              <h6 className="footer__list__heading">Products</h6>
              <ol>
                <li onClick={() => navigate("/email-warmup")}>Email Warmup</li>
                <li onClick={() => navigate("/funding-insights")}>
                  Funding Insights
                </li>
                <li onClick={() => navigate("/email-test")}>Email Spam Test</li>
                {/* <HashLink
                  to="/#how-it-works"
                  scroll={(el) => scrollWidthOffset(el)}
                >
                  {" "}
                  <li>How it works</li>{" "}
                </HashLink>
                <li
                  onClick={() =>
                    (window.location.href = "https://app.revli.com/login")
                  }
                >
                  Login
                </li> */}
              </ol>
            </li>
            <li className="footer__list text-center lg:text-left">
              <h6 className="footer__list__heading">Services</h6>
              <ol>
                <li onClick={() => navigate("/meeting-scheduling")}>
                  Meeting scheduling
                </li>
              </ol>
            </li>
            <li className="footer__list text-center lg:text-left">
              <h6 className="footer__list__heading">Company</h6>
              <ol>
                <li onClick={() => navigate("/blog")}>Blog</li>
                <li onClick={() => navigate("/about-us")}>About</li>

                {/* <li
                  onClick={() =>
                    (window.location.href =
                      "https://angel.co/company/revli/jobs")
                  }
                >
                  Careers
                </li> */}
              </ol>
            </li>

            <li className="footer__list text-center lg:text-left">
              <h6
                onClick={() => navigate("/pricing")}
                className="footer__list__heading cursor-pointer"
              >
                Pricing
              </h6>
            </li>
            <li className="footer__list text-center lg:text-left">
              <h6 className="footer__list__heading">Resources</h6>
              <ol>
                <li onClick={() => navigate("/faq")}>FAQ</li>
                <li
                  onClick={() =>
                    (window.location.href = "https://help.revli.com/en/")
                  }
                >
                  Help Center
                </li>
                <li onClick={() => navigate("/contact")}>Contact</li>

                <li onClick={() => navigate("/terms")}>Terms & Conditions</li>
                <li onClick={() => navigate("/privacy-policy")}>
                  Privacy Policy
                </li>
              </ol>
            </li>
          </ul>
        </div>
        <div
          className="pt-5 lg:pt-12 mt-8 lg:mt-[6.5rem] border-t border-opacity-30"
          style={{ borderColor: "1px solid rgba(201, 191, 191, 0.43)" }}
        >
          <p className="text-sm md:text-base text-white text-center letter-space02">
            Copyright&copy; 2024, Revli Inc. All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

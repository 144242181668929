import React, { useState, useEffect } from "react";
import logo from "../assets/images/logos/revli-logo.svg";
import arrowDown from "../assets/images/icons/chevron-down.svg";
import chevronDown from "../assets/images/icons/chevron-down.svg";
import { useNavigate, useLocation } from "react-router-dom";

const HeaderNew = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    // document.body.style.overflow = isMenuOpen ? "" : "hidden";
  };

  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const header = document.getElementById("hideHeader");
      if (prevScrollPos > currentScrollPos) {
        header.style.top = "32px";
      } else {
        header.style.top = "-80px";
      }
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const handleNavigate = () => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "auto" });
      window.scrollY(0);
      navigate(0);
      // window.location.reload();
    } else {
      navigate("/");
    }
  };

  return (
    <header
      id="hideHeader"
      className={`w-full h-[4.5rem] fixed top-8 left-0  z-10 ${
        isMenuOpen ? "mobile-header" : ""
      }`}
      style={{ transition: "all 300ms" }}
    >
      <div className="h-full container mx-auto">
        <div
          className="h-full flex items-center p-4 -mx-4 bg-[rgba(18,28,43,0.82)] sm:rounded-xl"
          style={{ backdropFilter: "blur(7.5px)" }}
        >
          <img
            src={logo}
            alt="logo"
            className="cursor-pointer h-6 pl-3 sm:pl-0 sm:h-auto"
            onClick={() => handleNavigate()}
          />
          <nav className="nav-list hidden xl:flex items-center mx-auto">
            <div className="relative flex items-center px-4 py-2 mr-[1.875rem] last:mr-0 cursor-pointer dropdown-label">
              <label className="text-border-color text-lg font-medium letter-space02 cursor-pointer">
                Products
              </label>
              <div className="w-5 h-5 flex items-center justify-center flex-shrink-0 ml-1">
                <img src={arrowDown} alt="arrow-down" />
              </div>
              <div className="dropdown-wrapper"></div>
              <div className="p-[0.169rem] dropdown-menu">
                <a
                  onClick={() => navigate("/email-warmup")}
                  className="dropdown-menu__list"
                >
                  Email Warmup
                </a>
                <a
                  onClick={() => navigate("/funding-insights")}
                  className="dropdown-menu__list"
                >
                  Funding Insights
                </a>
                <a
                  onClick={() => navigate("/email-test")}
                  className="dropdown-menu__list"
                >
                  Email Spam Test
                </a>
              </div>
            </div>
            <div className="relative flex items-center px-4 py-2 mr-[1.875rem] last:mr-0 cursor-pointer dropdown-label">
              <label className="text-border-color text-lg font-medium letter-space02 cursor-pointer">
                Services
              </label>
              <div className="w-5 h-5 flex items-center justify-center flex-shrink-0 ml-1">
                <img src={arrowDown} alt="arrow-down" />
              </div>
              <div className="dropdown-wrapper"></div>
              <div className="p-[0.169rem] dropdown-menu">
                <a
                  onClick={() => navigate("/meeting-scheduling")}
                  className="dropdown-menu__list"
                >
                  Meeting Scheduling
                </a>
              </div>
            </div>
            <div className="relative flex items-center px-4 py-2 mr-[1.875rem] last:mr-0 cursor-pointer dropdown-label">
              <label className="text-border-color text-lg font-medium letter-space02 cursor-pointer">
                Company
              </label>
              <div className="w-5 h-5 flex items-center justify-center flex-shrink-0 ml-1">
                <img src={arrowDown} alt="arrow-down" />
              </div>
              <div className="dropdown-wrapper"></div>
              <div className="p-[0.169rem] dropdown-menu ">
                <a
                  onClick={() => navigate("/blog")}
                  className="dropdown-menu__list"
                >
                  Blog
                </a>
                <a
                  onClick={() => navigate("/about-us")}
                  className="dropdown-menu__list"
                >
                  About
                </a>
              </div>
            </div>
            <div className="relative flex items-center px-4 py-2 mr-[1.875rem] last:mr-0 cursor-pointer">
              <label
                className="text-border-color text-lg font-medium letter-space02 cursor-pointer"
                onClick={() => navigate("/pricing")}
              >
                Pricing
              </label>
            </div>
            <div className="relative flex items-center px-4 py-2 mr-[1.875rem] last:mr-0 cursor-pointer dropdown-label">
              <label className="text-border-color text-lg font-medium letter-space02 cursor-pointer">
                Resources
              </label>
              <div className="w-5 h-5 flex items-center justify-center flex-shrink-0 ml-1">
                <img src={arrowDown} alt="arrow-down" />
              </div>
              <div className="dropdown-wrapper"></div>
              <div className="p-[0.169rem] dropdown-menu">
                <a
                  onClick={() => navigate("/faq")}
                  className="dropdown-menu__list"
                >
                  FAQ
                </a>
                <a
                  onClick={() =>
                    (window.location.href = "https://help.revli.com/en/")
                  }
                  className="dropdown-menu__list"
                >
                  Help Center
                </a>
                <a
                  onClick={() => navigate("/contact")}
                  className="dropdown-menu__list"
                >
                  Contact
                </a>
              </div>
            </div>
          </nav>
          <div className="hidden xl:flex items-center">
            <button
              className="btn btn--white text-lg px-5 py-[0.375rem]"
              onClick={() =>
                (window.location.href = "https://app.revli.com/login")
              }
            >
              Login
            </button>
            <button
              className="btn btn--primary text-lg ml-4 px-5 py-[0.375rem]"
              onClick={() =>
                (window.location.href = "https://app.revli.com/register")
              }
            >
              Get Started Free
            </button>
          </div>
          <div className="mobile-menu xl:hidden">
            <input
              type="checkbox"
              className="mobile-menu-checkbox hidden"
              id="mobileNavigation"
            />
            <label
              htmlFor="mobileNavigation"
              className="hamburger-icon-wrapper"
              id="menuToggle"
              onClick={toggleMenu}
            >
              <span className="hamburger-icon"></span>
            </label>
            <nav className="mobile-nav-list">
              <div className="mobile-nav-list__container h-full flex flex-col items-center">
                <div className="w-full h-[4.375rem] flex items-center flex-shrink-0 pl-8 border-b border-border">
                  <div className="cursor-pointer" onClick={() => navigate("/")}>
                    <img src={logo} alt="revli-logo" className="h-6" />
                  </div>
                </div>
                <div className="flex-1 w-full h-full overflow-y-auto">
                  <div className="w-full flex-shrink-0 transition-all">
                    <input
                      type="checkbox"
                      id="subMenuProducts"
                      className="hidden dropdown-nested-input"
                    />
                    <label
                      htmlFor="subMenuProducts"
                      className="relative w-full h-16 flex items-center justify-between border-b border-white border-opacity-30 text-[#B0B0B0] text-lg font-bold px-8 py-2 cursor-pointer"
                    >
                      Products
                      <img
                        src={chevronDown}
                        className="w-[0.75rem] h-[0.438rem] dropdown-arrow transition-all"
                        alt="arrow-down"
                      />
                    </label>
                    <div className="dropdown-nested pl-5">
                      <div
                        onClick={() => navigate("/email-warmup")}
                        className="relative w-full h-12 flex items-center justify-between text-[#B0B0B0] text-lg font-medium px-8 py-2 border-b border-white border-opacity-10 cursor-pointer"
                      >
                        Email Warmup
                      </div>
                      <div
                        onClick={() => navigate("/funding-insights")}
                        className="relative w-full h-12 flex items-center justify-between text-[#B0B0B0] text-lg font-medium px-8 py-2 border-b border-white border-opacity-10 cursor-pointer"
                      >
                        Funding Insights
                      </div>
                      <div
                        onClick={() => navigate("/email-test")}
                        className="relative w-full h-12 flex items-center justify-between text-[#B0B0B0] text-lg font-medium px-8 py-2 border-b border-white border-opacity-10 cursor-pointer"
                      >
                        Email Spam Test
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex-shrink-0 transition-all">
                    <input
                      type="checkbox"
                      id="subMenuServices"
                      className="hidden dropdown-nested-input"
                    />
                    <label
                      htmlFor="subMenuServices"
                      className="relative w-full h-16 flex items-center justify-between border-b border-white border-opacity-30 text-[#B0B0B0] text-lg font-bold px-8 py-2 cursor-pointer"
                    >
                      Services
                      <img
                        src={chevronDown}
                        className="w-[0.75rem] h-[0.438rem] dropdown-arrow transition-all"
                        alt="arrow-down"
                      />
                    </label>
                    <div className="dropdown-nested pl-5">
                      <div
                        onClick={() => navigate("/meeting-scheduling")}
                        className="relative w-full h-12 flex items-center justify-between text-[#B0B0B0] text-lg font-medium px-8 py-2 border-b border-white border-opacity-10 cursor-pointer"
                      >
                        Meeting Scheduling
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex-shrink-0 transition-all">
                    <input
                      type="checkbox"
                      id="subMenuCompany"
                      className="hidden dropdown-nested-input"
                    />
                    <label
                      htmlFor="subMenuCompany"
                      className="relative w-full h-16 flex items-center justify-between border-b border-white border-opacity-30 text-[#B0B0B0] text-lg font-bold px-8 py-2 cursor-pointer"
                    >
                      Company
                      <img
                        src={chevronDown}
                        className="w-[0.75rem] h-[0.438rem] dropdown-arrow transition-all"
                        alt="arrow-down"
                      />
                    </label>
                    <div className="dropdown-nested pl-5">
                      <div
                        onClick={() => navigate("/blog")}
                        className="relative w-full h-12 flex items-center justify-between text-[#B0B0B0] text-lg font-medium px-8 py-2 border-b border-white border-opacity-10 cursor-pointer"
                      >
                        Blog
                      </div>
                      <div
                        onClick={() => navigate("/about-us")}
                        className="relative w-full h-12 flex items-center justify-between text-[#B0B0B0] text-lg font-medium px-8 py-2 border-b border-white border-opacity-10 cursor-pointer"
                      >
                        About
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => navigate("/pricing")}
                    className="w-full h-16 flex items-center justify-between flex-shrink-0 border-b border-white border-opacity-30 text-[#B0B0B0] text-lg font-bold px-8 py-2 transition-all cursor-pointer"
                  >
                    Pricing
                  </div>
                  <div className="w-full flex-shrink-0 transition-all">
                    <input
                      type="checkbox"
                      id="subMenuResources"
                      className="hidden dropdown-nested-input"
                    />
                    <label
                      htmlFor="subMenuResources"
                      className="relative w-full h-16 flex items-center justify-between border-b border-white border-opacity-30 text-[#B0B0B0] text-lg font-bold px-8 py-2 cursor-pointer"
                    >
                      Resources
                      <img
                        src={chevronDown}
                        className="w-[0.75rem] h-[0.438rem] dropdown-arrow transition-all"
                        alt="arrow-down"
                      />
                    </label>
                    <div className="dropdown-nested pl-5">
                      <div
                        onClick={() => navigate("/faq")}
                        className="relative w-full h-12 flex items-center justify-between text-[#B0B0B0] text-lg font-medium px-8 py-2 border-b border-white border-opacity-10 cursor-pointer"
                      >
                        FAQ
                      </div>
                      <div
                        onClick={() =>
                          (window.location.href = "https://help.revli.com/en/")
                        }
                        className="relative w-full h-12 flex items-center justify-between text-[#B0B0B0] text-lg font-medium px-8 py-2 border-b border-white border-opacity-10 cursor-pointer"
                      >
                        Help Center
                      </div>
                      <div
                        onClick={() => navigate("/contact")}
                        className="relative w-full h-12 flex items-center justify-between text-[#B0B0B0] text-lg font-medium px-8 py-2 border-b border-white border-opacity-10 cursor-pointer"
                      >
                        Contact
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      (window.location.href = "https://app.revli.com/login")
                    }
                    className="w-full h-16 flex items-center justify-between flex-shrink-0 border-b border-white border-opacity-30 text-[#B0B0B0] text-lg font-bold px-8 py-2 transition-all cursor-pointer"
                  >
                    Login
                  </div>
                  <div
                    className="w-full h-16 flex items-center justify-between flex-shrink-0 border-b border-white border-opacity-30 text-[#B0B0B0] text-lg font-bold px-8 py-2 transition-all cursor-pointer"
                    onClick={() =>
                      (window.location.href = "https://app.revli.com/register")
                    }
                  >
                    Get Started Free
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderNew;

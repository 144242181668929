import React from "react";
import comprehensiveMarket from "../assets/images/icons/comprehensive-market.svg";
import funding from "../assets/images/icons/funding.svg";
import dataDriven from "../assets/images/icons/data-driven.svg";
import adaptive from "../assets/images/icons/adaptive.svg";
import expertGuidance from "../assets/images/icons/expert-guidance.svg";
import resultOriented from "../assets/images/icons/result-oriented.svg";

const AboutUsFeatures = () => {
  const aboutUsListData = [
    {
      img: comprehensiveMarket,
      title: "Advanced Technology",
      subTitle:
        "Leverage the latest in AI and data analytics, designed to provide you with insights and automation that outpaces the competition.",
    },
    {
      img: funding,
      title: "Cost Effective Solutions",
      subTitle:
        "Access affordable, high-quality tools that enhance your operations without straining your budget, giving you the freedom to invest in growth.",
    },
    {
      img: dataDriven,
      title: "Scalable Strategies",
      subTitle:
        "Grow your business with strategies and solutions designed to adapt to your increasing demands and ensure seamless scalability as you expand.",
    },
    {
      img: adaptive,
      title: "Adaptive Approach",
      subTitle:
        "We keep you ahead of the curve by quickly pivoting to data trends and market changes, keeping our strategy and solutions fresh and effective.",
    },
    {
      img: expertGuidance,
      title: "Expert Guidance and Support",
      subTitle:
        "Navigate the complex world of startup sales with our expert guidance and support, propelling your growth and helping you achieve results faster",
    },
    {
      img: resultOriented,
      title: "Results-Oriented Mindset",
      subTitle:
        "We build solutions with a direct line to revenue, focusing on high-value data and tools that boost ROI and drive business growth. ",
    },
  ];
  return (
    <section className="pt-[3.875rem] lg:pt-[7.75rem] pb-[3.875rem] relative">
      <div className="container mx-auto">
        <h2 className="text-3xl lg:text-[2.875rem] font-extrabold line-height3 text-center">
          Why choose <span className="text-primary-brand-dark">Revli</span>?
        </h2>
        <div className="mt-12 lg:mt-[7rem] relative">
          <ul className="flex lg:flex-wrap -mx-3 overflow-x-auto lg-overflow-x-initial scrollbar-none overflow-x-behaviour-contain">
            {aboutUsListData.map((item, index) => (
              <li
                key={index}
                className="w-11/12 lg:w-4/12 flex-shrink-0 lg-flex-shrink-initial px-3 mb-6"
              >
                <div className="h-full card-box">
                  <figure className="fig-wrapper">
                    <img src={item.img} alt="icon-telegram" />
                  </figure>
                  <div className="flex flex-col mt-5">
                    <h5 className="text-lg lg:text-xl font-bold">
                      {item.title}
                    </h5>
                    <p className="secondary-font text-secondary lg:text-lg letter-space02 mt-3">
                      {item.subTitle}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AboutUsFeatures;

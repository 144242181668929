import { graphql } from 'babel-plugin-relay/macro';

export const BlogQuery = graphql`
  query BlogQuery($articlesKind:String!) {
    blogArticles(articlesKind: $articlesKind) {
      id
      createdAt
      updatedAt
      title
      context
      kind
      sortOrder
      image
      imageUrl
      slug
    }
  }
`;

import React, { useState } from "react";
import HeroCommon from "../components/common/hero-section";
import Footer from "../components/Footer";
import HeaderNew from "../components/HeaderNew";
import { mutate } from "../relay";
import { SendContactUsEmailMutation } from "../service/mutations/SendContactUsEmailMutation";
import { isEmpty, isValidEmail } from "../utils";

const initialValues = {
  name: "",
  email: "",
  company: "",
  phone: "",
};

const ContactUs = () => {
  const [values, setValues] = useState(initialValues);
  const [countryCode, setCountryCode] = useState("+1");
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handleSubmit = () => {
    const combinedPhone = `${countryCode}${values.phone}`;

    if (isEmpty(values.name)) {
      setError({ ...error, name: "Please enter name" });
      return;
    }
    if (isEmpty(values.email) || !isValidEmail(values.email)) {
      setError({ ...error, email: "Please enter valid email" });
      return;
    }
    if (isEmpty(values.company)) {
      setError({ ...error, company: "Please enter company" });
      return;
    }
    if (isEmpty(values.phone)) {
      setError({ ...error, phone: "Please enter phone number" });
      return;
    }

    const submissionValues = { 
      ...values, 
      phone: combinedPhone,
    };

    mutate({
      mutation: SendContactUsEmailMutation,
      input: submissionValues,
      onSuccess: (data) => {
        if (data.sendContactUsEmail) {
          setSuccess(true);
          setValues(initialValues);
          setCountryCode("+1");
          setSuccess(false)
        }
      },
      onErrors: (error) => {
        // showToaster('error', error[0].message);
      },
    });
  };

  return (
    <main className="">
      <section>
        <HeaderNew />
        <HeroCommon
          title={"Contact Us"}
          subTitle={
            "Want to get in touch? We'd love to hear from you. Please fill out the form below or email support@revli.com"
          }
        />
        <section className="relative pb-[6.25rem] -mt-[200px]">
          <div className="main-container">
            <div className="card-section p-5 lg:p-12">
              <div className="-mx-5 flex items-start flex-wrap">
                <div className="px-5 w-full sm:w-6/12 mb-6">
                  <label className="input-field-label">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    className="input-field"
                    placeholder=""
                  />
                  {error.name && <p className="error-msg">{error.name}</p>}
                </div>
                <div className="px-5 w-full sm:w-6/12 mb-6">
                  <label className="input-field-label">Email</label>
                  <input
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    className="input-field"
                    placeholder=""
                  />
                  {error.email && <p className="error-msg">{error.email}</p>}
                </div>
              </div>
              <div className="-mx-5 flex items-start flex-wrap">
                <div className="px-5 w-full sm:w-6/12 mb-6">
                  <label className="input-field-label">Company</label>
                  <input
                    type="text"
                    name="company"
                    value={values.company}
                    onChange={handleChange}
                    className="input-field"
                    placeholder=""
                  />
                  {error.company && (
                    <p className="error-msg">{error.company}</p>
                  )}
                </div>
                <div className="px-5 w-full sm:w-6/12 mb-6">
                  <label className="input-field-label">Phone (optional)</label>
                  <div className="relative">
                    <input
                      className="absolute top-[1px] left-[5px] border-none border-r border-r-border-color w-10 h-[95%] outline-none z-[1]"
                      style={{
                        borderRight: "1px solid #BDCAD6",
                        padding: "0 8px",
                      }}
                      placeholder="+1"
                      type="number"
                      value={countryCode}
                      onChange={handleCountryCodeChange}
                    />
                    <input
                      type="number"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      className="input-field"
                      placeholder=""
                      style={{ paddingLeft: 50 }}
                    />
                  </div>
                  {error.phone && <p className="error-msg">{error.phone}</p>}
                </div>
              </div>
              <div className="w-full">
                <label className="input-field-label">Your Message</label>
                <textarea
                  className="input-field resize-none"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  rows="4"
                  cols="50"
                ></textarea>
                {error.message && <p className="error-msg">{error.message}</p>}
              </div>
              {success && (
                <p className="secondary-font text-26 text-center  pb-4">
                  {" "}
                  Thanks for reaching out to us{" "}
                </p>
              )}
              <div className="mt-6">
                <button
                  onClick={handleSubmit}
                  disabled={success}
                  className="btn btn--primary w-full sm:w-7/12 md:w-6/12 sm:px-20 py-2 mx-auto"
                >
                  Send Message
                </button>
              </div>
            </div>
            <div className="mt-20">
              <div className="card-section py-12 px-6 lg:px-10">
                <h2 className="text-2xl md:text-4xl lg:text-[2.75rem] font-extrabold text-center lg-line-height3">
                  Let's talk sales
                </h2>
                <p className="secondary-font text-lg lg:text-xl font-medium text-center mt-4">
                  Get in touch for a free assessment with one of our B2B growth
                  strategists.
                </p>
                <div className="w-11/12 lg:w-8/12 mx-auto flex flex-col mt-10">
                  <div>
                    <h3 className="text-lg lg:text-xl font-bold">
                      On the 30 minute call we will:
                    </h3>
                  </div>
                  <ul className="ml-4 mt-4">
                    <li className="text-xl list-disc letter-space02 mb-1 last:mb-0">
                      Get to know you and your business
                    </li>
                    <li className="text-xl list-disc letter-space02 mb-1 last:mb-0">
                      Review your goals and targets
                    </li>
                    <li className="text-xl list-disc letter-space02 mb-1 last:mb-0">
                      Present our software/services and how they might fit
                    </li>
                    <li className="text-xl list-disc letter-space02 mb-1 last:mb-0">
                      Establish next step
                    </li>
                  </ul>
                  <button
                    className="btn btn--primary btn--h60 text-lg lg:text-[1.438rem] mt-10"
                    onClick={() => {
                      window.open("https://calendly.com/revli", "_blank");
                    }}
                  >
                    Schedule a meeting
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </section>
    </main>
  );
};

export default ContactUs;
